import MDinput from '@/components/MDinput';
import PickRec from '@/components/DSE/PickRec';
import { validatorRequire } from '@/utils/validators';

export function getFormDefinition(vue) {
  return {
    hermesClientID: {
      type: PickRec,
      defaultValue: null,
      props: {
        caption: 'hermes.client',
        entity: 'HermesClient',
        valueField: 'id',
        labelField: 'accountNumber',
        inputField: 'accountNumber',
        columns: [{ field: 'name' }, { field: 'accountNumber', label: 'hermes.accountNumber' }],
        maxlength: 10,
        required: true,
        usePagination: false,
        hideInput: false,
        suggest: true,
        readonly: true
      },
      rules: [{ validator: validatorRequire }]
    },
    hermesProductID: {
      type: PickRec,
      defaultValue: null,
      props: {
        caption: 'hermes.product',
        entity: 'HermesProduct',
        valueField: 'id',
        labelField: 'code',
        inputField: 'description',
        columns: [{ field: 'code', label: 'hermes.productCode' }, 'description'],
        maxlength: 16,
        required: true,
        usePagination: false,
        hideInput: false,
        suggest: true,
        readonly: true
      },
      rules: [{ validator: validatorRequire }]
    },
    hermesZoneID: {
      type: PickRec,
      defaultValue: null,
      props: {
        caption: 'hermes.zone',
        entity: 'HermesZone',
        valueField: 'id',
        labelField: 'zone',
        inputField: 'zone',
        columns: [{ field: 'zone', label: 'hermes.zone' }, 'description'],
        maxlength: 16,
        required: true,
        usePagination: false,
        hideInput: false,
        suggest: true,
        readonly: true
      },
      rules: [{ validator: validatorRequire }]
    },
    combi: {
      type: MDinput,
      defaultValue: '',
      props: {
        caption: 'hermes.combi',
        maxlength: 32,
        required: false
      },
      rules: []
    },
    rate: {
      type: MDinput,
      defaultValue: '',
      props: {
        caption: 'hermes.rate',
        type: 'number',
        precision: 2,
        min: 0,
        max: 99999.9999,
        required: true,
        showThousandSeparator: true
      },
      rules: [{ validator: validatorRequire }]
    }
  };
}

export default getFormDefinition;

export function createItemFromFormData(vue, form) {
  return form;
}

export function createFormDataFromItem(vue, item) {
  const form = {
    ...item
  };
  return form;
}
